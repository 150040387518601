.link {
    text-decoration: none;
    font-size: 16px;
    color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-1));
    font-weight: 500;
  
    &:hover {
      text-decoration: underline;
      color: #000;
    }
  
    &[data-active] {
      &, &:hover {
        color: #000;
      }
    }
}

.lastLink {
  text-decoration: none;
  font-size: 14px;
  color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-1));
  font-weight: 500;
}