.control {
  
    &:hover {
      color: #000;
    }
  
    &[data-active] > label > span {
      color: #000;
    }
}

.richText > * {
  word-break: break-all;
}
